.p-calendar .p-inputtext {
  border: 2px solid #009688; /* Custom border color */
}

.p-calendar .p-datepicker {
  background-color: #e0f2f1; /* Custom background color for the popup */
}

/* Add more custom styles as needed */
.multi-month-calendar .p-monthpicker {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.multi-month-calendar .p-monthpicker .p-monthpicker-month {
  flex: 0 0 45%; /* Adjust the percentage as needed to fit two months per row */
  margin: 0.5rem; /* Optional: Add some spacing between the months */
}

.p-calendar .p-datepicker {
  width: 800px; /* Set width of the popup */
  /* Other styles... */
}

.p-calendar .p-datepicker table {
  font-size: 0.85em; /* Smaller text */
}

.p-calendar .p-monthpicker .p-monthpicker-month {
  flex: 0 0 45%; /* Adjust for better fitting */
  margin: 0.25rem; /* Smaller margins */
}

/* Adjust navigation buttons if needed */
.p-calendar .p-datepicker .p-datepicker-header {
  /* Styles for header */
}

.p-calendar .p-datepicker .p-datepicker-prev,
.p-calendar .p-datepicker .p-datepicker-next {
  /* Styles for prev and next buttons */
}
